import React, { useCallback, useContext, useEffect, useState } from 'react';

// external packages
import ReactPlayer from 'react-player';

// context
import { PlayerContext } from '../../contexts/PlayerContext';
import { registerConsumption } from '../../services/registerConsumption';

// player handlers
import {
    handleReady,
    handlePlay,
    handlePause,
    handleBuffering,
    handleBufferEnd,
    handleEnded,
    handleProgress,
    handleDuration,
    handleAdPlaying,
    handleAdDuration,
    handlePlayerReady,
} from './playerHandlers';

// interfaces
import { PlayerAudio, PlayerProps } from '../../interfaces/player/player.interface';

// google ad manager
import { google, loadImaSdk } from '@alugha/ima';

export const Player = React.memo(() => {
    const playerContext = useContext(PlayerContext);
    const { props } = playerContext;
    const { playerRef, playerState, setPlayerState } = props as PlayerProps;
    const {
        controls,
        duration,
        light,
        loop,
        muted,
        playbackRate,
        playedSecondList,
        playedSeconds,
        playing,
        url,
        volume,
    } = playerState;

    // Ad management variables
    const [adTag, setAdTag] = useState<string>('');
    const [audioAdComponent, setAudioAdComponent] = useState<HTMLElement | null>();
    const [audioAdElement, setAudioAdElement] = useState<Element | null>();

    const saveConsumption = useCallback(() => {
        if (playerContext.data) {
            let {
                name: audioName,
                clientName,
                publisher,
                autoPlay,
                topicName,
            } = playerContext?.data?.currentAudio as PlayerAudio;

            if (!topicName) topicName = '';
            if (!publisher) publisher = '';

            registerConsumption(duration, playedSecondList, audioName, clientName, publisher, topicName, autoPlay);
        }
    }, [playerContext.data, playedSecondList, duration]);

    useEffect(() => {
        if (playerContext.data) {
            setAdTag(playerContext.data.currentAudio.adTag);
        }
    }, [playerContext?.data]);

    // effect for consumption, it stores the played seconds
    useEffect(() => {
        const playedSecond = Math.round(playedSeconds);
        if (!playedSecondList.includes(playedSecond)) {
            playedSecondList.push(playedSecond); // save the played second
        }
    }, [playedSeconds, playedSecondList]);

    useEffect(() => {
        if (playerState?.adPlaying) {
            handleAdPlaying(setPlayerState);
        }
    }, [playerState.adPlaying, setPlayerState]);

    useEffect(() => {
        if (playerState.adPlayed && playerState.adSkippable) {
            window.dispatchEvent(new Event('skip'));
        }
    }, [playerState.adPlayed, playerState.adSkippable, setPlayerState]);

    useEffect(() => {
        if (playerState.adDuration > 0) {
            handleAdDuration(setPlayerState, playerState.adDuration);
        }
    }, [playerState.adDuration, setPlayerState]);

    useEffect(() => {
        if (audioAdElement && adTag?.length > 0 && playerState.isPlayerReady) {
            audioAdElement.setAttribute('id', 'audio-element');

            loadImaSdk()
                .then((ima: any) => {
                    const adDisplayContainer: google.ima.AdDisplayContainer = new ima.AdDisplayContainer(
                        document.getElementById('ad-container'),
                        audioAdElement,
                    );
                    adDisplayContainer.initialize();

                    let adsLoader = new ima.AdsLoader(adDisplayContainer);
                    let adsRequest = new ima.AdsRequest();
                    let adsManager: any = null;
                    let adsLoaded = false;

                    // the VAST tag
                    adsRequest.adTagUrl = adTag;

                    // Specify the linear and nonlinear slot sizes. This helps the SDK to
                    // select the correct creative if multiple are returned.
                    adsRequest.linearAdSlotWidth = audioAdElement?.clientWidth;
                    adsRequest.linearAdSlotHeight = audioAdElement?.clientHeight;
                    adsRequest.nonLinearAdSlotWidth = audioAdElement?.clientWidth;

                    // Pass the request to the adsLoader to request ads
                    adsLoader.requestAds(adsRequest);

                    // If ad request does not succeed in 2500 ms, skip ad, set player as ready
                    setTimeout(function () {
                        handleReady(setPlayerState, window);
                    }, 2500);

                    function onContentPauseRequested() {
                        if (!playerState.adPlayed) {
                            setPlayerState(state => ({
                                ...state,
                                adPlaying: true,
                                playing: false,
                            }));
                        }
                    }

                    function onContentResumeRequested() {
                        if (!playerState.adPlayed) {
                            setPlayerState(state => ({
                                ...state,
                                adPlaying: false,
                                adPlayed: true,
                                playing: true,
                            }));
                        }
                    }

                    function onAdError(adErrorEvent: google.ima.AdErrorEvent) {
                        // Handle the error logging.
                        console.error(adErrorEvent.getError());
                        if (adsManager) {
                            adsManager.destroy();
                        }
                    }

                    function onAdsManagerLoaded(adsManagerLoadedEvent: any) {
                        // Instantiate the AdsManager from the adsLoader response and pass it the audio element
                        adsManager = adsManagerLoadedEvent.getAdsManager(audioAdElement);

                        adsManager.addEventListener(ima.AdErrorEvent.Type.AD_ERROR, onAdError);
                        adsManager.addEventListener(ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, onContentPauseRequested);
                        adsManager.addEventListener(
                            ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
                            onContentResumeRequested,
                        );
                        adsManager.addEventListener(ima.AdEvent.Type.LOADED, onAdLoaded);

                        window.addEventListener('skip', e => {
                            adsManager.destroy();
                        });

                        // Set ads manager as ready
                        setPlayerState(state => ({
                            ...state,
                            adsManagerReady: true,
                        }));

                        loadAds();
                    }

                    adsLoader.addEventListener(
                        ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
                        onAdsManagerLoaded,
                        false,
                    );

                    function loadAds() {
                        // Prevent this function from running on if there are already ads loaded
                        if (adsLoaded) {
                            return;
                        }
                        adsLoaded = true;

                        setPlayerState(state => ({
                            ...state,
                            adLoaded: adsLoaded,
                        }));
                    }

                    function onAdLoaded(adEvent: google.ima.AdEvent) {
                        const ad = adEvent.getAd();
                        if (ad) {
                            if (ad.getDuration() > 0) {
                                setPlayerState(state => ({
                                    ...state,
                                    adDuration: ad.getDuration(),
                                }));
                            }
                            if (ad.getSkipTimeOffset() > -1) {
                                setPlayerState(state => ({
                                    ...state,
                                    adSkipTimeOffset: ad.getSkipTimeOffset(),
                                }));
                            }
                        }
                    }

                    audioAdElement.addEventListener('play', function (event) {
                        try {
                            if (adsManager && adsLoaded) {
                                // Prevent triggering immediate playback when ads are loading
                                event.preventDefault();

                                var width = 1;
                                var height = 1;

                                adsManager.init(width, height, ima.ViewMode.NORMAL);
                                adsManager.start();
                            }
                        } catch (adError) {
                            // Play the audio without ads, if an error occurs
                            console.error(adError);
                        }
                    });
                })
                .catch(() => {
                    console.error('SDK could not be loaded. Check your ad blocker!');
                    // Ad could not be loaded, set player is as ready
                    handleReady(setPlayerState, window);
                });
        }
    }, [adTag, audioAdElement, playerState.adPlayed, playerState.adsManagerReady, playerState.adLoaded, playerState.isReady, setPlayerState]);

    useEffect(() => {
        if (audioAdElement && playerState.adLoaded) {
            // Ad is loaded, player is now ready
            handleReady(setPlayerState, window);
        }
    }, [audioAdElement, playerState.adLoaded]);

    useEffect(() => {
        if (audioAdComponent) {
            // Once audio ad component is rendered, set child element that will load google ad sdk
            setAudioAdElement(audioAdComponent?.firstElementChild);
        }
    }, [audioAdComponent]);

    useEffect(() => {
        if (playerState.isPlayerReady) {
            if (adTag) {
                // Pre roll ad tag, set audio ad component that will load google ad sdk
                setAudioAdComponent(document.getElementById('audio-component'));
            } else {
                // No pre-roll ad tag, set player as ready
                handleReady(setPlayerState, window);
            }
        }
    }, [playerState.isPlayerReady]);

    // Add effect to register consumption on window close
    useEffect(() => {
        window.addEventListener('beforeunload', saveConsumption);
        return () => {
            window.removeEventListener('beforeunload', saveConsumption);
        };
    }, [saveConsumption]);

    return (
        <ReactPlayer
            id="audio-component"
            ref={playerRef}
            data-test="player-component"
            width={'0'}
            height={0}
            style={{ display: 'none' }}
            url={url}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onReady={() => handlePlayerReady(setPlayerState, window)}
            onPlay={() => handlePlay(setPlayerState)}
            onPause={() => handlePause(setPlayerState)}
            onBuffer={() => handleBuffering(setPlayerState)}
            onBufferEnd={() => handleBufferEnd(setPlayerState)}
            onEnded={() => handleEnded(setPlayerState)}
            onError={e => console.error('onError', e)}
            onProgress={state => handleProgress(playerState, setPlayerState, state)}
            onDuration={duration => handleDuration(setPlayerState, duration)}
            progressInterval={200}
        />
    );
});
