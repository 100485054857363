// react player
import { OnProgressProps } from "react-player/base";

// interfaces
import { PlayerState } from "../../interfaces/player/player.interface";


export const handlePlayerReady = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>, window: any) => {
    window?.android_odia_interface?.on_player_ready();
    setPlayerState(state => ({
        ...state,
        isPlayerReady: true
    }))
}


export const handleReady = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>, window: any) => {
    window?.android_odia_interface?.on_player_ready();
    setPlayerState(state => ({
        ...state,
        isReady: true
    }))
}

export const handleBuffering = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        isBuffering: true
    }))
}

export const handleAdPlaying = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        adPlaying: true,
        playing: false
    }))
}

export const handleAdPlayed = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        adPlaying: false,
        adPlayed: true,
        adDuration: 0,
        playing: true
    }))
}

export const handleAdDuration = (
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>,
    adDuration: number) => {
    setPlayerState(state => ({
        ...state,
        adDuration: adDuration,
    }))
}

export const handleBufferEnd = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        isBuffering: false
    }))
}

export const handlePlayPause = (
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    setPlayerState(state => ({
        ...state,
        playing: !state.playing,
        muted: false
    }))
}

export const handlePlay = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        playing: true
    }))
}

export const handlePause = (setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>) => {
    setPlayerState(state => ({
        ...state,
        playing: false
    }))
}

export const handleSeekTo = (seconds: number, player: React.MutableRefObject<null | any>, increment = 0) => {
    if (player.current) player.current?.seekTo(seconds + increment);
}

export const handleProgress = (
    playerState: PlayerState,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>,
    newState: OnProgressProps
) => {
    if (!playerState.seeking) {
        setPlayerState(state => ({
            ...state,
            ...newState
        }));
    }
}

export const handleEnded = (
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    setPlayerState(state => ({
        ...state,
        playing: state.loop,
        isAlreadyPlayed: true
    }))
}

export const handleDuration = (
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>,
    duration: number
) => {
    setPlayerState(state => ({
        ...state,
        duration: duration
    }))
}

export const handleOnPlaybackRateChange = (
    playerState: PlayerState,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    const { playbackRate } = playerState;

    let newPlaybackRate: number;
    switch (playbackRate) {
        case 1.0:
            newPlaybackRate = 1.5
            break;
        case 1.5:
            newPlaybackRate = 2
            break;
        default:
            newPlaybackRate = 1
            break;
    }

    const newPlaybackRateLabel = `${newPlaybackRate}x`;

    setPlayerState(state => ({
        ...state,
        playbackRate: newPlaybackRate,
        playbackRateLabel: newPlaybackRateLabel
    }));
}

export const handleMute = (
    playerState: PlayerState,
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    if (playerState.muted) {
        setPlayerState((state: PlayerState) => ({
            ...state,
            muted: false
        }));
    } else {
        setPlayerState((state: PlayerState) => ({
            ...state,
            muted: true
        }));
    }
};



export const handlePlayAd = (
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    setPlayerState(state => ({
        ...state,
        adPlaying: true,
        playing: false
    }))
}

export const handleSkipAd = (
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>
) => {
    setPlayerState(state => ({
        ...state,
        adPlayed: true,
        adPlaying: false,
        adSkippable: true,
        adSkipTimeOffset: -1,
        adDuration: 0,
        playing: true,
        muted: false
    }))
}
