import axios from 'axios';

// default types
import { defaultAudio } from '../types/default/defaultAudio';
import { defaultPlayer } from '../types/default/defaultPlayer';
import { playerTypes } from '../types/playerTypes';

// interfaces
import { PlayerType, PlayerStyle, PlayerData, Player } from '../interfaces/player/player.interface';
import { PlayerRequestParams, PlayerRequestValidation } from '../interfaces/player/playerRequest.interface';

const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en-US';

const defaultPlayerValidation: PlayerRequestValidation = {
    isValidated: false,
    validationResult: 'Could not validate the request',
    language: defaultLanguage,
    playerType: { ...defaultPlayer.type },
    playerStyle: {
        visual: { ...defaultPlayer.style.visual },
        buttons: { ...defaultPlayer.style.buttons },
        language: { ...defaultPlayer.style.language }
    },
    playerData: {
        currentAudio: defaultAudio,
        audioQueue: []
    },
    topicList: []
}

export const validatePlayerData = async (params: any, demo = false, adTag = ''): Promise<PlayerRequestValidation> => {
    const { fileId, userId, lang }: PlayerRequestParams = params.params;
    const backendRoute: string = process.env.REACT_APP_BACKEND_ROUTE || '';

    defaultPlayerValidation.language = (lang === '' ? defaultLanguage : lang);
    if (demo) {
        return {
            ...defaultPlayerValidation,
            isValidated: true
        }
    }

    if (fileId === '' || userId === '' || backendRoute === '') {
        return defaultPlayerValidation;
    }

    let referrer = document.referrer;
    if (referrer.includes('localhost') || referrer.includes('odialab')) {
        referrer = "local";
    }

    let requestUrl = `${backendRoute}/player?client=${userId}&audioName=${fileId}`
    if (referrer.length > 0) requestUrl += `&referrer=${referrer}`

    const resp = await axios(requestUrl);

    if (resp.status !== 200) {
        return {
            ...defaultPlayerValidation,
            validationResult: resp.statusText
        }
    }

    const { type, style, data, vastTag }: { type: PlayerType, style: PlayerStyle, data: PlayerData, vastTag: string } = resp.data;

    const playerType = defaultPlayerValidation.playerType as PlayerType;
    const playerData = defaultPlayerValidation.playerData as PlayerData;
    let playerStyle = defaultPlayerValidation.playerStyle as PlayerStyle;

    // fill missing fields for player state
    const { audioQueue, currentAudio } = data;
    if (currentAudio) {
        currentAudio.autoPlay = false;
        currentAudio.selected = false;
    }
    audioQueue.forEach(audio => {
        audio.autoPlay = false;
        audio.selected = false;
        audio.adTag = adTag !== '' ? adTag : vastTag;
    })

    // try to fetch the requested style
    const player: Player = playerTypes.filter(p => p.type.mode === type.mode && p.type.design === type.design)[0]
    if (player) playerStyle = player.style as PlayerStyle;

    const validationResult: PlayerRequestValidation = {
        ...defaultPlayerValidation,
        isValidated: true,
        validationResult: 'Successful validation',
        playerType: {
            ...playerType,
            ...type
        },
        playerStyle: {
            visual: {
                ...playerStyle.visual,
                ...style?.visual
            },
            buttons: {
                ...playerStyle.buttons,
                ...style?.buttons
            },
            language: {
                ...playerStyle.language,
                ...style?.language
            }
        },
        playerData: {
            currentAudio: {
                ...playerData.currentAudio,
                ...data.currentAudio,
                adTag
            },
            audioQueue: {
                ...playerData.audioQueue,
                ...data.audioQueue
            }
        }
    }

    return validationResult;
}